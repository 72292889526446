import React from 'react'
import { Link } from 'gatsby'

import { getLocalizedPath } from '../../i18n'
import { PageContext } from '../../pageContext'

const getPropsFactory = param => props => {
    const obj = {
        className: param.activeClassName + ' ' + param.className,
    }
    if (
        props.location.hash === '#about' &&
        props.href.includes('#about') &&
        props.isCurrent === false
    ) {
        return obj
    } else {
        return props.isCurrent && props.location.hash !== '#about' ? obj : null
    }
}

const LocalizedLink = ({ to, ...props }) => (
    <PageContext.Consumer>
        {({ locale }) => {
            return (
                <Link
                    getProps={getPropsFactory(props)}
                    {...props}
                    to={getLocalizedPath(to, locale)}
                />
            )
        }}
    </PageContext.Consumer>
)

export default LocalizedLink
