import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from '../LocalizedLink'
import LanguageSwitcher from '../LanguageSwitcher'
import './Header.sass'
import logoSVG from '../../images/logo/logo.svg'

const Header = () => {
    return (
        <header className="Header">
            <nav
                id="mainNav"
                className="navbar navbar-expand-lg fixed-top bg-white"
            >
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img
                            src={logoSVG}
                            height="50"
                            className="d-inline-block align-top"
                            alt="Advateq"
                        />
                    </Link>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        Menu
                        <i className="fas fa-bars"></i>
                    </button>

                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav ml-auto order-0">
                            <li className="nav-item">
                                <Link
                                    to="/"
                                    className="nav-link"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="home.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/#about"
                                    className="nav-link"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="about.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/products"
                                    className="nav-link"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="products.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/reference"
                                    className="nav-link"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="reference.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/download"
                                    className="nav-link"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="download.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/contact"
                                    className="nav-link"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="contact.title" />
                                </Link>
                            </li>
                        </ul>

                        <LanguageSwitcher cls="nav-separ order-1" />

                        <div className="socials order-3">
                            <a
                                className="mr-3"
                                href="https://www.youtube.com/channel/UCKcqhvO77aF0nPGfIRzSLeQ/featured"
                                title="Youtube"
                            >
                                <i className="fab fa-youtube"></i>
                            </a>
                            <a
                                href="https://www.facebook.com/Advateq/"
                                title="Facebook"
                            >
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header

// class Header extends React.Component {
//     componentDidMount() {
//         $(document).ready(function() {
//             console.log('document ready occurred!')
//         })
//     }

//     handleClick = () => {
//         console.log('click')
//     }

//     render() {
//         return (
//             <header className="Header">
//                 <nav
//                     id="mainNav"
//                     className="navbar navbar-expand-lg fixed-top bg-white"
//                 >
//                     <div className="container">
//                         <Link to="/" className="navbar-brand">
//                             <img
//                                 src={logoSVG}
//                                 height="50"
//                                 className="d-inline-block align-top"
//                                 alt="Advateq"
//                             />
//                         </Link>

//                         <button
//                             className="navbar-toggler"
//                             type="button"
//                             data-toggle="collapse"
//                             data-target="#navbarSupportedContent"
//                             aria-controls="navbarSupportedContent"
//                             aria-expanded="false"
//                             aria-label="Toggle navigation"
//                         >
//                             Menu
//                             <i className="fas fa-bars"></i>
//                         </button>

//                         <div
//                             className="collapse navbar-collapse"
//                             id="navbarSupportedContent"
//                         >
//                             <ul className="navbar-nav ml-auto order-0">
//                                 <li className="nav-item">
//                                     <Link
//                                         to="/"
//                                         className="nav-link"
//                                         activeClassName="active"
//                                     >
//                                         <FormattedMessage id="home.title" />
//                                     </Link>
//                                 </li>

//                                 <li
//                                     className="nav-item"
//                                     onClick={this.handleClick}
//                                 >
//                                     <Link
//                                         to="/#about"
//                                         className="nav-link"
//                                         activeClassName="active"
//                                     >
//                                         <FormattedMessage id="home.about.title" />
//                                     </Link>
//                                 </li>

//                                 <li className="nav-item">
//                                     <Link
//                                         to="/products"
//                                         className="nav-link"
//                                         activeClassName="active"
//                                     >
//                                         <FormattedMessage id="products.title" />
//                                     </Link>
//                                 </li>

//                                 <li className="nav-item">
//                                     <Link
//                                         to="/download"
//                                         className="nav-link"
//                                         activeClassName="active"
//                                     >
//                                         <FormattedMessage id="download.title" />
//                                     </Link>
//                                 </li>

//                                 <li className="nav-item">
//                                     <Link
//                                         to="/contact"
//                                         className="nav-link"
//                                         activeClassName="active"
//                                     >
//                                         <FormattedMessage id="contact.title" />
//                                     </Link>
//                                 </li>
//                             </ul>

//                             <LanguageSwitcher cls="nav-separ order-1" />

//                             <div className="socials order-3">
//                                 <a
//                                     href="https://www.youtube.com/channel/UCKcqhvO77aF0nPGfIRzSLeQ/featured"
//                                     title="Youtube"
//                                 >
//                                     <i className="fab fa-youtube"></i>
//                                 </a>
//                                 <a
//                                     href="https://www.facebook.com/Advateq/"
//                                     title="Facebook"
//                                 >
//                                     <i className="fab fa-facebook"></i>
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </nav>
//             </header>
//         )
//     }
// }

// export default Header
