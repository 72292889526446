import React from 'react'
import { Link } from 'gatsby'

import { languages, getLocalizedPath } from '../../i18n'

import { PageContext } from '../../pageContext'

import './LanguageSwitcher.sass'

const LanguageSwitcher = ({ cls }) => (
    <PageContext.Consumer>
        {({ originalPath, locale }) => (
            <div className={'dropdown language-switcher ' + cls}>
                {languages.map(lang =>
                    lang.locale === locale ? (
                        <button
                            className="btn dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span
                                className={'flag-icon flag-icon-' + lang.flag}
                            ></span>
                            <span className="lang-label">{lang.label}</span>
                        </button>
                    ) : (
                            <div
                                className="dropdown-menu dropdown-menu-language"
                                aria-labelledby="dropdownMenuLink"
                            >
                                <Link
                                    key={lang.locale}
                                    to={getLocalizedPath(originalPath, lang.locale)}
                                    className="dropdown-item"
                                >
                                    <span
                                        className={'flag-icon flag-icon-' + lang.flag}
                                    ></span>
                                    <span className="lang-label">{lang.label}</span>
                                </Link>
                            </div>
                        )
                )}
            </div>
        )}
    </PageContext.Consumer>
)

export default LanguageSwitcher
