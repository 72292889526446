import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import Link from '../LocalizedLink'

import './Footer.sass'

const Footer = ({location}) => {
    const data = useStaticQuery(graphql`
        query {
            footerLogoImage: file(
                relativePath: { eq: "logo/Advateq_logotyp_white.png" }
            ) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)
    return (
        <footer className={ location.pathname.split('/')[1] ==="produkty" || location.pathname.split('/')[1] ==="products" ? "products-footer" : "" }>
            <div className="clearfix"></div>
            <div className="container" >
                {/* <FormattedHTMLMessage id="footer.title" /> */}

                <div className="row">
                    <div className="col-12 col-md">
                        {/* <img
                        className="mb-2 logo"
                        src="../img/logo/Advateq_logotyp_white.png"
                        alt="Advateq"
                    /> */}
                        <Img
                            fixed={data.footerLogoImage.childImageSharp.fixed}
                            className="mb-2"
                            alt="Advateq"
                        />

                        <small className="d-block mb-3 text-muted">
                            &copy; 2019
                        </small>
                        <ul className="nav socials">
                            <li className="nav-item">
                                <a
                                    href="https://www.youtube.com/channel/UCKcqhvO77aF0nPGfIRzSLeQ/featured"
                                    title="youtube"
                                    className="nav-link text-muted"
                                >
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="https://www.facebook.com/Advateq/"
                                    title="facebook"
                                    className="nav-link text-muted"
                                >
                                    <i className="fab fa-facebook-f"></i>
                                    {/* <FontAwesomeIcon icon={['fas', 'code']} /> */}
                                    {/* <FontAwesomeIcon icon={faFacebook} /> */}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5>
                            <FormattedMessage id="footer.navigation" />
                        </h5>
                        <ul id="footer-nav" className="list-unstyled text-small">
                            <li className="nav-item">
                                <Link
                                    to="/"
                                    className="text-muted"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="home.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/#about"
                                    className="text-muted"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="about.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/products"
                                    className="text-muted"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="products.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/reference"
                                    className="text-muted"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="reference.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/download"
                                    className="text-muted"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="download.title" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/contact"
                                    className="text-muted"
                                    activeClassName="active"
                                >
                                    <FormattedMessage id="contact.title" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5>
                            <FormattedMessage id="footer.contact" />
                        </h5>
                        <ul className="list-unstyled contact-ul">
                            <li className="mb-3 d-flex">
                                <i className="fas fa-map-marker-alt"></i>
                                <div className="ml-3">
                                    <span>
                                        Nové sady 988/2
                                        <br />
                                        Staré Brno, 602 00 Brno
                                    </span>
                                </div>
                            </li>
                            <li className="mb-3 d-flex">
                                <i className="fas fa-phone"></i>
                                <div className="ml-3">
                                    <span>+420 732 319 478</span>
                                    {/* <span className="d-block text-muted text-small">
                                        Po - Pá 9h - 17h
                                    </span> */}
                                </div>
                            </li>
                            <li className="mb-3 d-flex">
                                <i className="fas fa-envelope"></i>
                                <div className="ml-3">
                                    <a href="/" title="advateq">
                                        info@advateq.cz
                                    </a>
                                </div>
                            </li>
                            <li className="mb-3 d-flex">
                                <i className="fas fa-info"></i>
                                <div className="ml-3">
                                    <a
                                        href="https://or.justice.cz/ias/ui/rejstrik-firma.vysledky?subjektId=1052916&typ=UPLNY"
                                        alt="IC"
                                    >
                                        IČ: 08229465
                                    </a>
                                    <br />
                                    <a
                                        href="https://rejstrik-firem.kurzy.cz/08229465/advateq-sro/"
                                        alt="DIC"
                                    >
                                        DIČ: CZ08229465
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5>
                            <FormattedMessage id="footer.request" />
                        </h5>
                        <p className="text-muted">
                            <FormattedMessage id="footer.request.text" />
                        </p>
                        {/* <a
                            className="btn btn-custom btn-custom-white"
                            href="/kontakt.html#quick-contact"
                            title="Zašlete nám poptávku"
                        >
                            
                        </a> */}

                        <Link
                            to="/contact#quick-contact"
                            className="btn btn-custom btn-custom-white"
                            activeClassName="active"
                        >
                            <FormattedMessage id="footer.request.button" />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
