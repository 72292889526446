module.exports = [
    {
        default: true,
        locale: 'cs',
        flag: 'cz',
        label: 'CZ',
        routes: {
            '/': '/',
            '/#about': '/#about',
            '/contact': '/kontakt',
            '/contact#quick-contact': '/kontakt#quick-contact',
            '/products': '/produkty',
            '/download': '/kestazeni',
            '/404': '/404',
            '/404.html': '/404.html',
            '/reference': '/reference',
            '/products#doplnujici-prvky': '/produkty#doplnujici-prvky',
            '/products#rizeni-a-regulace': '/produkty#rizeni-a-regulace',
            '/products#odhlucnovaci-kryty': '/produkty#odhlucnovaci-kryty',
            '/products#predzasobniky': '/produkty#predzasobniky',
            '/products#linearni-podavace': '/produkty#linearni-podavace',
            '/products#kruhove-podavace': '/produkty#kruhove-podavace',
        },
    },
    {
        locale: 'en',
        flag: 'gb',
        label: 'EN',
        routes: {
            '/': '/',
            '/#about': '/#about',
            '/contact': '/contact',
            '/contact#quick-contact': '/contact#quick-contact',
            '/products': '/products',
            '/download': '/download',
            '/404': '/404',
            '/404.html': '/404.html',
            '/reference': '/reference',
            '/products#doplnujici-prvky': '/products#doplnujici-prvky',
            '/products#rizeni-a-regulace': '/products#rizeni-a-regulace',
            '/products#odhlucnovaci-kryty': '/products#odhlucnovaci-kryty',
            '/products#predzasobniky': '/products#predzasobniky',
            '/products#linearni-podavace': '/products#linearni-podavace',
            '/products#kruhove-podavace': '/products#kruhove-podavace',            
        },
    },
]
