import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'

import { getDisplayName } from '../utils'

// import '../styles/all.min.css'
// import '../styles/common.sass'

// if (typeof window !== 'undefined') {
//     // eslint-disable-next-line global-require
//     require('smooth-scroll')('a[href*="#"]', {
//         speed: 300,
//         offset: 97,
//     })
// }

const withLayout = Component => {
    const WrapperComponent = props => {
        // console.log(props)
        return (
            <React.Fragment>
                <Helmet>
                    <link
                        href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/css/flag-icon.min.css"
                        rel="stylesheet"
                    />
                </Helmet>
                <Header />

                <Component {...props} />

                <Footer {...props} />
            </React.Fragment>
        )
    }
    WrapperComponent.displayName = `Layout(${getDisplayName(Component)})`
    return WrapperComponent
}

export default withLayout
